body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
}


  /* normal img-responsive is display: block, which doesn't allow images to be centered when in a
   text-align: center span, which is what summernote does to align things. so we use inline-block
   instead. */
.myimg-responsive {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

body.dark-mode .user-content a span[style*="color"] {
  color: #7da8d4 !important;
}

body.dark-mode .user-content *[style*="color"], /* This works "color" and "background-color" properties */
body.dark-mode .user-content *[style*="background"], /* This gets the "background" property */
body.dark-mode .user-content *[style*="COLOR"], /* Style entries can be case sensitive. Some old messages use caps */
body.dark-mode .user-content font {
  /* HTML 4 tag used by a few older mssgs.  It has different syntax */
  color: var(--dark-mode-text-color) !important;
  /* For the selectors above, set all the text to the standard color */
  background-color: transparent !important;
  /* and remove backgrounds so the Groups.io bkg shows through */
}

body.dark-mode .user-content a span {
  /*  User code typically adjusts link colors by putting a span inside an anchor tag  */
  color: #7da8d4 !important;
  background-color: transparent !important;
}

body.dark-mode .toc li:before {
  color: var(--dark-mode-text-color);
}